<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      "Choose a value": "Escolha um valor",
      "Or enter the amount of your donate": "Ou digite o valor da sua doação",
      "Value is required.": "Valor é obrigatório",
      "New Donate": "Nova Doação",
      Rules: "Regras",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Status",
      Payment: "Pagamento",
      Details: "Detalhes",
      Pending: "Pendente",
      Approved: "Aprovado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "Choose a value": "Elija un valor",
      "Or enter the amount of your donate":
        "Ou introduzca la cantidad de su donación",
      "Value is required.": "Se requiere valor.",
      "New Donate": "Nueva Donar",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Estado",
      Payment: "Pago",
      Details: "Detalles",
      Pending: "Pendiente",
      Approved: "Aprobado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
  },
  data() {
    return {
      loading: {
        plans: false,
      },

      plans: null,

      table: {
        heade: ["Order", "Date", "Pagamento", "Total", "Status", ""],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getPlan() {
      api.get("plans").then((response) => {
        if (response.data.status == "success") {
          this.plans = response.data.list;
        }
      });
    },
    getOrders() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("store/orders")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getPlan();
    this.getOrders();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Novo Contrato") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-3" v-for="(plan, index) in plans" :key="index">
            <router-link class="text-dark" :to="'/contracts/new/' + index">
              <div class="card">
                <div class="card-body p-4 text-center">
                  <h5 class="text-uppercase mb-3">
                    <strong>{{ plan.name }}</strong>
                  </h5>
                  <template v-if="plan.value.min == plan.value.max">
                    <div>R$ {{ plan.value.min | points }}</div>
                  </template>
                  <template v-else>
                    <div class="d-flex justify-content-center">
                      <div>de R$ {{ plan.value.min | points }}</div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <div>até R$ {{ plan.value.max | points }}</div>
                    </div>
                  </template>
                  <div class="d-flex justify-content-center" v-if="plan.bonus == 'yes'">
                    <div class="align-self-center">Ganho por Indicações</div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="align-self-center">Duração de 365 dias</div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="align-self-center">
                      Tokenização de {{ plan.yield.percent }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <div class="align-self-center">
                      Pontos de Carreira {{ plan.points }}
                    </div>
                  </div>
                  <div class="mt-3">
                    <button class="btn btn-default btn-sm">ESCOLHER</button>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card d-none">
          <div class="card-header bg-soft-dark rounded-top">Meus Pedidos</div>
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="row">
                      <div v-if="row === 'id'">
                        <strong>#{{ data }}</strong>
                      </div>
                      <div v-else-if="row === 'total'">
                        {{ data | currency }}
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                    <td class="text-right">
                      <router-link
                        :to="'/store/order/' + td.id"
                        class="btn btn-default btn-sm btn-rounded"
                      >
                        {{ t("View") }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>